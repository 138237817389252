










import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import { TwitchPlayer, TwitchPlayerEvent } from 'twitch-player';

@Component
export default class extends Vue {
  @Prop({ type: String, required: true }) readonly channel!: string;
  @Prop(Boolean) readonly unmuted!: boolean;
  elemID = `TwitchPlayer_${uuid()}`;
  domain = window.location.hostname;
  player!: TwitchPlayer;

  get chanLC(): string {
    return this.channel.toLowerCase();
  }

  mounted(): void {
    this.player = TwitchPlayer.FromOptions(this.elemID, {
      channel: this.channel,
      parent: [this.domain],
      width: '100%',
      height: '100%',
      muted: !this.unmuted,
    });
    this.player.addEventListener(TwitchPlayerEvent.PLAY, () => {
      const currentQuality = this.player.getQuality();
      if (currentQuality !== '480p30') {
        this.player.setQuality('480p30');
        // this.player.setMuted(!this.unmuted);
      }
    });
  }

  @Watch('chanLC')
  onChannelChange(newVal: string, oldVal: string): void {
    if (newVal !== oldVal) {
      this.player.setChannel(newVal);
    }
  }
}
